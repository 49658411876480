<template>
<div id="app">
   <div v-if="PageLayout=='default'" data-page="urunler" class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
      <div id="app2">
         <div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed ">
            <div class="kt-header-mobile__logo">
               <RouterLink :to="{name:'Home'}">
                <img alt="Logo" src="{{asset('assets/media/logos/logo-light.png')}}" />
               </RouterLink>
            </div>
            <div class="kt-header-mobile__toolbar">
               <button class="kt-header-mobile__toggler kt-header-mobile__toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
               <button class="kt-header-mobile__toggler" id="kt_header_mobile_toggler"><span></span></button>
               <button class="kt-header-mobile__topbar-toggler" id="kt_header_mobile_topbar_toggler"><i class="flaticon-more"></i></button>
            </div>
         </div>
         <div class="kt-grid kt-grid--hor kt-grid--root">
         
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
               <div class="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
                   <LeftMenu/>
               </div>
               <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                  <div id="kt_header" class="kt-header kt-grid__item  kt-header--fixed ">
                     <HeaderSection/>
                  </div>
                  <div>
                     <router-view :key="$route.fullPath" />
                  </div>          
                  <FooterSection/>
               </div>
            </div>
         </div>
         <div id="kt_scrolltop" class="kt-scrolltop">
            <i class="fa fa-arrow-up"></i>
         </div>
      </div>
   </div>
   
   <div v-if="PageLayout=='login'">
    <router-view :key="$route.fullPath" />
   </div>
   <div v-if="PageLayout=='sale'">
    <router-view :key="$route.fullPath" />
   </div>   
</div>   
</template>

<script>
import LeftMenu from './components/LeftMenu.vue'
import HeaderSection from './components/HeaderSection.vue'
import FooterSection from './components/FooterSection.vue'
export default {
  name: 'App',
  data(){
    return {
      PageLayout:"default"
    }
  },
  components: {
    LeftMenu,
    HeaderSection,
    FooterSection
  },
  watch: {
    '$route' (to, ) {
      //console.log(to.name+from.name+this.$route.name);
      this.layoutChanged(to.name);
    }
  }, 
  mounted(){
      this.layoutChanged(this.$route.name);     
  },
  methods:{
    layoutChanged(routeName){
      if(routeName=="Login"){
        this.PageLayout = "login";
      }
      else if(routeName=="Sale"){
        this.PageLayout = "sale";
      } 
      else{
        this.PageLayout = "default";
      }  
    },
    apptest(){
     console.log("TEST");
    }
  }
}
</script>