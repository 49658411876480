// router/index.js
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomePage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: '/Product',
    name: 'ProductList',
    component: () => import('../views/ProductListPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Product/:id',
    name: 'ProductView',
    component: () => import('../views/ProductViewPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/User',
    name: 'UserList',
    component: () => import('../views/UserListPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Order/:id',
    name: 'OrderView',
    component: () => import('../views/OrderViewPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Order',
    name: 'OrderList',
    component: () => import('../views/OrderListPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Setting',
    name: 'Setting',
    component: () => import('../views/SettingPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Sync',
    name: 'Sync',
    component: () => import('../views/SyncPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/Sale',
    name: 'Sale',
    component: () => import('../views/SalePage.vue'),
    meta: {
      requiresAuth: true
    }
  }   
];

const router = createRouter({
  history: createWebHistory(process.env.NODE_ENV === 'production' ? '/' : '/'),
  routes
});

router.beforeEach((to, from, next)=> {
  to;from;next;
  let token = localStorage.getItem("access_token") ?? "";
  //const isAuthenticated = false;
  //MyApp.config.globalProperties.isAuthenticated=true
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token!="") {
      next(); 
    } else {
      next({name:'Login'});
    }
  }
  else{
    next(); 
  }
});

export default router;